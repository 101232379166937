/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getBonsaiBase, grow } from "../helpers/BonsaiHelpers";
import * as BONSAI from "../helpers/BonsaiHelpers";
import ShootType from "../types";

const Wrapper = styled.div`
  text-align: center;
  line-height: 13px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 99%;
  @media (max-width: 1000px) {
    z-index: -1;
  }
`;

interface BonsaiTextProps {
  faded: boolean;
}

const Text = styled.p`
  padding: 0;
  margin: 0;
  z-index: -3;
  opacity: ${(props: BonsaiTextProps) => (props.faded ? 0.4 : 1)};
  line-height: 15px;

  @media (max-width: 1000px) {
    font-size: 10px;
    line-height: 12px;
  }

  @media (max-width: 700px) {
    font-size: 9px;
    line-height: 11px;
  }

  @media (max-width: 480px) {
    font-size: 8px;
    line-height: 9px;
  }
`;

type BonsaiProps = {
  faded: boolean;
};

const Bonsai = ({ faded }: BonsaiProps): JSX.Element => {
  const getEmptyBonsai = (): string[][] => {
    // this was taking too long using array.fill()
    // new Array(rows).fill("\u00A0").map(() => new Array(cols).fill("\u00A0"))
    // https://dev.to/cbrannen9a/what-s-the-fastest-way-to-populate-an-array-in-javascript-549j
    const arrayOfSpaces = [];
    for (let i = 0; i < BONSAI.rows; i += 1) {
      arrayOfSpaces[i] = new Array(BONSAI.cols);
      for (let j = 0; j < BONSAI.cols; j += 1) {
        arrayOfSpaces[i][j] = "\u00A0";
      }
    }
    return arrayOfSpaces;
  };

  const [bonsai, setBonsai] = useState<string[][]>(getEmptyBonsai());

  const resetBonsai = async () => {
    const array = getEmptyBonsai();
    setBonsai(array);
  };

  const growBonsai = async () => {
    await resetBonsai();

    const startYPos = BONSAI.rows - 1;
    const startXPos = Math.round(BONSAI.cols / 2) - 1;
    grow(
      startYPos,
      startXPos,
      BONSAI.lives,
      0,
      ShootType.trunk,
      bonsai,
      setBonsai
    );
  };

  useEffect(() => {
    const startGrowing = async () => {
      growBonsai();
    };
    startGrowing();
  }, []);

  return (
    <Wrapper>
      {bonsai.map((line, idx) => {
        const joinedLine = line.join("");
        return (
          <Text
            key={idx}
            faded={faded}
            dangerouslySetInnerHTML={{ __html: joinedLine }}
          />
        );
      })}
      {getBonsaiBase()
        .split("\n")
        .map((val, idx) => (
          <Text
            key={idx}
            faded={faded}
            dangerouslySetInnerHTML={{ __html: val }}
          />
        ))}
    </Wrapper>
  );
};

export default Bonsai;
