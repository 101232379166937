import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    primaryColor: string;
    secondaryColor: string;
    background: string;
    body: string;
    text: string;
    color: string;
    toggleBorder: string;
  }
}

export const lightTheme: DefaultTheme = {
  primaryColor: "#333",
  color: "#333",
  secondaryColor: "#05386b",
  body: "#FFF",
  text: "#363537",
  toggleBorder: "#FFF",
  background: "#EDF5E1",
};

export const darkTheme: DefaultTheme = {
  primaryColor: "#fff",
  color: "#fff",
  secondaryColor: "#cacaca",
  body: "#363537",
  text: "#FAFAFA",
  toggleBorder: "#6B8096",
  background: "#22282D",
};

export type Theme = {
  background: string;
  body: string;
  text: string;
  toggleBorder: string;
};
