enum ShootType {
  trunk = 1,
  shootLeft = 2,
  shootRight = 3,
  dying = 4,
  dead = 5,
}

export type DisplayingMode = {
  me: boolean;
  projects: boolean;
};

export default ShootType;
