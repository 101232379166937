import React from "react";
import styled from "styled-components";
import { BsMoon, BsSun } from "react-icons/bs";

type ThemeChangeButtonProps = {
  isDarkTheme: boolean;
  handleThemeChange: (checked: boolean) => void;
};

const DarkThemeIcon = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  z-index: 2;
  :focus,
  :hover {
    animation: wobble 1s 1;
  }

  @media (max-width: 1000px) {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  @keyframes wobble {
    25% {
      transform: rotate(15deg);
    }
    50% {
      transform: rotate(-30deg);
    }
    75% {
      transform: rotate(5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;

const ThemeChangeButton = ({
  isDarkTheme,
  handleThemeChange,
}: ThemeChangeButtonProps): JSX.Element => {
  return (
    <DarkThemeIcon aria-label="light theme toggle button">
      {isDarkTheme ? (
        <BsSun
          onClick={() => {
            handleThemeChange(false);
          }}
          style={{ paddingLeft: "20px" }}
          size={35}
        />
      ) : (
        <BsMoon
          aria-label="light theme toggle button"
          onClick={() => {
            handleThemeChange(true);
          }}
          style={{ paddingLeft: "20px" }}
          size={35}
        />
      )}
    </DarkThemeIcon>
  );
};

export default ThemeChangeButton;
