import React from "react";
import styled from "styled-components";
import startPageImage from "../assets/startpage.png";
import awardItImage from "../assets/awardit.png";
import pontificateImage from "../assets/pontificate.png";
import noisyImage from "../assets/noisy.png";
import gitKeepDemoGif1 from "../assets/gitKeepDemo1.gif";
import gitKeepDemoGif2 from "../assets/gitKeepDemo2.gif";
import missingLinkImage from "../assets/missinglink.png";
import missingLink2Image from "../assets/missinglink-2.png";

const Projects = (): JSX.Element => {
  const Wrapper = styled.div`
    position: absolute;
    top: 30px;
    text-align: left;
    padding: 10px;
    padding-bottom: 2rem;
    width: 640px;
    font-size: 18px;
    z-index: 1;
    @media (max-width: 1000px) {
      padding-top: 170px;
      overflow-wrap: break-word;
      width: auto;
      left: 0;
      z-index: 1;
    }
    backdrop-filter: blur(1px);
  `;

  return (
    <Wrapper>
      <h3>
        I write the occasional project when I&apos;m inspired. In order of
        completion date.
      </h3>
      <hr />
      <h3>
        <b>startpage</b> [
        <a href="https://github.com/allister-grange/startpage">repo</a>] [
        <a href="https://startpage.allistergrange.com/">site</a>]
      </h3>
      <img
        src={startPageImage}
        alt="preview of my start page website"
        width="100%"
        style={{ borderRadius: 10 }}
      />
      <p>
        I&apos;ve wanted a custom &apos;New Tab&apos; page for quite some time
        now, so I figured I&apos;d build one over the Christmas period. It uses
        about 5 API&apos;s to pull down info on things that I regularly search
        for every day. I would love to integrate this with an Apple Watch to
        give my live heart rate and more metrics, but it seems that that&apos;s
        limited by the Apple API at the moment.
        <br />
        <br />
        The frontend is written in TypeScript with React in the NextJS
        framework. NextJS and Vercel hosting is probably the best use of PAAS
        I&apos;ve ever used, I&apos;ll continue to use it for sites in the
        future.
      </p>
      <hr />
      <h3>
        <b>missinglink (WIP)</b> [
        <a href="https://missinglink.allistergrange.com/">site</a>]
      </h3>
      <img
        src={missingLinkImage}
        alt="preview of my missinglink website"
        width="100%"
        style={{ borderRadius: 10 }}
      />
      <img
        src={missingLink2Image}
        alt="preview of my missinglink website"
        width="100%"
        style={{ borderRadius: 10 }}
      />
      <p>
        This website is a call to Metlink to sort out their buses! It uses their{" "}
        <a href="https://opendata.metlink.org.nz/apis">public api</a> to parse
        out late/early/cancelled buses and show them in realtime so that people
        can visualise their delays. There&apos;s both a page for statistics over
        time, and a live map to show you bus positions.
        <br />
        <br />
        The frontend is written in TypeScript with React, it&apos;s hosted in an
        S3 bucket behind CloudFront. The backend is written in Dotnet Core using
        a Postgres database, hosted in an EC2 instance.
      </p>
      <hr />
      <h3>
        <b>awardit</b> [
        <a href="https://github.com/allister-grange/AwardIt">repo</a>] [
        <a href="https://awardit.info/">site</a>]
      </h3>
      <img
        src={awardItImage}
        alt="preview of my awardit website"
        width="100%"
        style={{ borderRadius: 10 }}
      />
      <p>
        This website was built due to numerous requests of users on the{" "}
        <a href="https://old.reddit.com/r/theydidthemath">
          &apos;r/theydidthemath&apos;
        </a>{" "}
        subreddit wanting to see how much the awards on various posts cost.
        <br />
        <br />
        It uses AWS lambda functions with AWS DynamoDB, it&apos;s hosted in an
        S3 bucket behind CloudFront.
      </p>
      <hr />
      <h3>
        pontificate [
        <a href="https://github.com/allister-grange/pontificate-client">repo</a>
        ] [<a href="https://www.pontificate.click/">site</a>]
      </h3>
      <img
        src={pontificateImage}
        alt="preview of my pontificate website"
        width="100%"
        style={{ borderRadius: 10 }}
      />
      <p>
        Wanting to play the board game{" "}
        <a href="https://en.wikipedia.org/wiki/Articulate!">Articulate</a>{" "}
        anywhere without the board, I took inspiration from{" "}
        <a href="https://www.jackboxgames.com/">Jackbox</a> and built an online
        game. The game is played with the board on a laptop or casted to a TV,
        with the players joining in on their phones.
        <br />
        <br />
        The code was written with a Node.js backend with Socket.io for the
        connections, with React on the frontend. It uses Redis for the DB layer
        for it&apos;s speed{" "}
      </p>
      <hr />
      <h3>
        noisy [<a href="https://github.com/allister-grange/noisy">repo</a>]
      </h3>
      <img
        src={noisyImage}
        alt="preview of my noisy mobile app"
        width="100%"
        style={{ borderRadius: 10 }}
      />
      <p>
        Noisy is a background noise generator designed to be simple and
        intuitive.
        <br />
        <br />
        Written in React Native, using TypeScript.
      </p>
      <hr />
      <h3>
        gitkeep [<a href="https://github.com/allister-grange/gitkeep">repo</a>]
      </h3>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <img
          src={gitKeepDemoGif1}
          alt="preview of my pontificate website"
          height="500"
          style={{ borderRadius: 10 }}
        />
        <img
          src={gitKeepDemoGif2}
          alt="preview of my pontificate website"
          height="500"
          style={{ borderRadius: 10 }}
        />
      </div>
      <p>
        My life is in markdown documents. I have all my notes from work, my
        to-do lists, my diary, project ideas, books read; they all sit in GitHub
        repos.
        <br />
        <br />
        To interact with them on my mobile, I built a mobile app to use GitHub’s
        API to update my markdown files on the go. This was built with React
        Native.
      </p>
      <hr />
    </Wrapper>
  );
};

export default Projects;
