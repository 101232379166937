import React from "react";
import styled from "styled-components";

const MeDescription = (): JSX.Element => {
  const Wrapper = styled.div`
    position: absolute;
    top: 30px;
    text-align: left;
    padding: 10px;
    width: 640px;
    font-size: 18px;
    height: 90%;
    @media (max-width: 1000px) {
      padding-top: 170px;
      overflow-wrap: break-word;
      width: auto;
      z-index: 1;
      left: 0;
    }
    backdrop-filter: blur(1px);
  `;

  return (
    <Wrapper>
      <p>
        Hi, I&apos;m Allister.
        <br />
        <br />
        From 2020 to 2021, I helped build and grow the devops practise at the
        Department of Internal Affairs. Before that, for late 2018 - 2019, I
        worked on building and maintaining Java webapps for the National Library
        of New Zealand.
        <br />
        <br />I love to sauna, I&apos;m big into running, and have a fascination
        for bonsais. I&apos;m a friendly, outgoing and approachable person, I
        pride myself on being open and transparent. My tech passion is the
        preservation and organisation of my thoughts, as you can see in my
        projects; I have multiple{" "}
        <a href="https://github.com/allister-grange/gitkeep">projects</a>{" "}
        dedicated to my handling of markdown files. I think it would be amazing
        to have indexable data about what I did at work, what I learned that
        day, pretty much anything I can write down, all in a useable format I
        can look back on in 20 years. I&apos;m trying to get there by building a
        personalised wiki for my own consumption.
        <br />
        <br />
        To keep updated with my work, follow me on{" "}
        <a href="https://github.com/allister-grange">Github</a>
        . I hate the thought of running a blog, it&apos;s not really my style,
        but maybe one day one will pop up here, never say never and all that.
        <br />
        <br />
        Contact Email:{" "}
        <a href="mailto:allistergrange@gmail.com">allistergrange@gmail.com</a>
        <br />
        LinkedIn:{" "}
        <a href="mailto:allistergrange@gmail.com">
          https://www.linkedin.com/in/allister-grange-3b3927171/
        </a>
      </p>
    </Wrapper>
  );
};

export default MeDescription;
