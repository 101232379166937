import React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { DisplayingMode } from "../types";
import ThemeChangeButton from "./ThemeChangeButton";

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  z-index: 1;
  display: "flex",
  width: "100%",
`;

const NameHeader = styled.h1`
  font-weight: 500;
  line-height: 1.1;
  font-size: 35px;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 1000px) {
    width: 80%;
    font-weight: 200;
    line-height: 0.9;
    font-size: 25px;
    text-align: left;
    display: inline;
  }
`;

const LinkHeader = styled.button`
  font-weight: 50 !important;
  line-height: 1.7;
  font-size: 23px;
  font-family: "Quicksand";
  background: none !important;
  border: none;
  padding: 0 !important;
  text-align: left;
  width: 80%;
  cursor: pointer;
  vertical-align: middle;
  z-index: 3;
  transition: all 0.2s;
  @media (max-width: 1000px) {
    font-size: 20px;
    line-height: 1.2;
  }
  &:hover {
    transform: translateY(-2px);
  }
  &:active {
    transform: translateY(-1px);
  }
`;

const LinkATagHeader = styled.a`
  font-weight: 50 !important;
  line-height: 1.7;
  font-size: 23px;
  font-family: "Quicksand";
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
  width: 80%;
  z-index: 3;
  transition: all 0.2s;
  @media (max-width: 1000px) {
    line-height: 1.2;
    font-size: 20px;
  }
  &:hover {
    transform: translateY(-2px);
  }
  &:active {
    transform: translateY(-1px);
  }
`;

interface LineProps {
  width: number;
  isDarkTheme: boolean;
}

const Line = styled.hr`
  width: ${(props: LineProps) => props.width}%;
  border: solid 1px
    ${(props: LineProps) => (props.isDarkTheme ? "#fff" : "#333")};
  @media (max-width: 1000px) {
    display: none;
  }
`;

const LineForMobile = styled.hr`
  width: 100vw;
  margin-top: 15px;
  display: none;
  @media (max-width: 1000px) {
    display: inline;
  }
`;

type SidePanelProps = {
  setBonsaiFaded: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplayingMode: (displayMode: DisplayingMode) => void;
  displayingMode: DisplayingMode;
  isDarkTheme: boolean;
  handleThemeChange: (checked: boolean) => void;
};

const SidePanel = ({
  setBonsaiFaded,
  setDisplayingMode,
  displayingMode,
  isDarkTheme,
  handleThemeChange,
}: SidePanelProps): JSX.Element => {
  const onLinkClick = (buttonName: string): any => {
    if (buttonName === "me") {
      if (displayingMode.me) {
        setBonsaiFaded(false);
      } else {
        setBonsaiFaded(true);
      }
      setDisplayingMode({ me: !displayingMode.me, projects: false });
    } else if (buttonName === "projects") {
      if (displayingMode.projects) {
        setBonsaiFaded(false);
      } else {
        setBonsaiFaded(true);
      }
      setDisplayingMode({ me: false, projects: !displayingMode.projects });
    }
  };

  return (
    <Wrapper>
      <NameHeader>
        Allister Grange
        <span>
          <ThemeChangeButton
            handleThemeChange={handleThemeChange}
            isDarkTheme={isDarkTheme}
          />
        </span>
      </NameHeader>
      <Line width={75} isDarkTheme={isDarkTheme} />

      <LinkHeader onClick={(e) => onLinkClick("me")}>
        me {displayingMode.me && "←"}
      </LinkHeader>
      <LinkHeader onClick={(e) => onLinkClick("projects")}>
        projects {displayingMode.projects && "←"}
      </LinkHeader>
      <LinkATagHeader href="https://github.com/allister-grange/">
        github
      </LinkATagHeader>
      <LinkATagHeader href="/AllisterGrangeCV.pdf">cv</LinkATagHeader>
      <LinkATagHeader href="mailto:allistergrange@gmail.com">
        email
      </LinkATagHeader>

      <ReactTooltip
        place="top"
        type={isDarkTheme ? "light" : "dark"}
        effect="float"
      />
      <LineForMobile />
    </Wrapper>
  );
};

export default SidePanel;
