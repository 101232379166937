import React, { useEffect, useState } from "react";
import styled, {
  ThemeProvider,
  createGlobalStyle,
  DefaultTheme,
} from "styled-components";
import Bonsai from "./components/Bonsai";
import { darkTheme, lightTheme } from "./theme/theme";
import SidePanel from "./components/SidePanel";
import "./theme/font.css";
import MeDescription from "./components/MeDescription";
import Projects from "./components/Projects";
import { DisplayingMode } from "./types";
import UseThemeDetector from "./hooks/UseThemeDetector";
import ThemeChangeButton from "./components/ThemeChangeButton";
import Spotify from "./components/Spotify";

const GlobalStyle = createGlobalStyle`
body {
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
}

html, #root, body, .App {
  margin: 0;
  min-height: 100%;
  overflow-x: hidden;
  font-family: 'Quicksand';
}

button {
  color: ${({ theme }) => theme.text};
}
a {
  color: ${({ theme }) => theme.text};
}
a:visited {
  color: ${({ theme }) => theme.text};
}
hr {
  color: ${({ theme }) => theme.secondaryColor};
}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  @media (max-width: 1000px) {
    flex-direction: column;
    flex: 1 0 auto;
  }
`;

const SideBarContainer = styled.nav`
  position: fixed;
  top: 27px;
  right: 50%;
  margin-right: 340px;
  @media (max-width: 1000px) {
    position: static;
    margin: auto;
    margin-top: 0;
  }
`;

const MainContainer = styled.main`
  max-width: 640px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1000px) {
    padding-left: 0px;
    padding-bottom: 10px;
    width: 100vw;
  }
`;

function App(): JSX.Element {
  const { isDarkTheme: isDefaultDarkTheme } = UseThemeDetector();
  const defaultTheme = isDefaultDarkTheme ? darkTheme : lightTheme;
  const [theme, setTheme] = useState<DefaultTheme>(defaultTheme);
  const [isDarkTheme, setIsDarkTheme] = useState(isDefaultDarkTheme);
  const [bonsaiFaded, setBonsaiFaded] = useState(false);
  const [displayingMode, setDisplayingMode] = useState<DisplayingMode>({
    me: false,
    projects: false,
  });

  useEffect(() => {
    const pathName = window.location.pathname;
    if (pathName === "/projects") {
      setDisplayingMode({ me: false, projects: true });
    } else if (pathName === "/me") {
      setDisplayingMode({ me: true, projects: false });
    }
  }, []);

  const changeDisplayMode = (displayMode: DisplayingMode) => {
    if (displayMode.me) {
      window.history.replaceState(null, "Allister Grange | Me", "/me");
    } else if (displayMode.projects) {
      window.history.replaceState(
        null,
        "Allister Grange | Projects",
        "/projects"
      );
    } else {
      window.history.replaceState(null, "Allister Grange", "/");
    }

    setDisplayingMode(displayMode);
  };

  const handleThemeChange = (checked: boolean) => {
    if (checked) {
      setTheme(darkTheme);
      setIsDarkTheme(true);
    } else {
      setTheme(lightTheme);
      setIsDarkTheme(false);
    }
  };

  return (
    <>
      <GlobalStyle theme={theme} />
      <ThemeProvider theme={theme}>
        <Wrapper theme={theme}>
          <SideBarContainer>
            <SidePanel
              setBonsaiFaded={setBonsaiFaded}
              setDisplayingMode={changeDisplayMode}
              displayingMode={displayingMode}
              isDarkTheme={isDarkTheme}
              handleThemeChange={handleThemeChange}
            />
          </SideBarContainer>
          <MainContainer>
            <Bonsai faded={bonsaiFaded} />
            {displayingMode.me && <MeDescription />}
            {displayingMode.projects && <Projects />}
          </MainContainer>
          {!displayingMode.me && !displayingMode.projects && <Spotify />}
        </Wrapper>
      </ThemeProvider>
    </>
  );
}

export default App;
