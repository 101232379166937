import { useEffect, useState } from "react";

type UseThemeDetector = {
  isDarkTheme: boolean;
  setIsDarkTheme: (value: React.SetStateAction<boolean>) => void;
};

const useThemeDetector = (): UseThemeDetector => {
  const getCurrentTheme = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());

  const mqListener = (e: any) => {
    setIsDarkTheme(e.matches);
  };

  useEffect(() => {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    darkThemeMq.addListener(mqListener);
    return () => darkThemeMq.removeListener(mqListener);
  }, []);

  return { isDarkTheme, setIsDarkTheme };
};

export default useThemeDetector;
