import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PauseIcon from "../assets/pause.png";
import { getNowPlaying } from "../helpers/spotify";

const Wrapper = styled.div`
  position: absolute;
  top: 100px;
  right: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;

  @media (max-width: 1000px) {
    position: static;
  }
`;

const Spotify = (): JSX.Element => {
  const [songPlaying, setSongPlaying] = useState(false);
  const [songName, setSongName] = useState("");
  const [artistName, setArtistName] = useState("");

  useEffect(() => {
    const fetchCurrentSong = async () => {
      const res = await getNowPlaying();
      const data = await res.json();

      setSongPlaying(data.is_playing);
      setSongName(data.item.name);
      setArtistName(data.item.artists[0].name);
    };

    fetchCurrentSong();
    setInterval(fetchCurrentSong, 10000);
  }, []);

  return (
    <Wrapper>
      {songPlaying ? (
        <>
          <p style={{ paddingLeft: "15" }}>
            {songName} - {artistName}
            <span style={{ color: "grey" }}> - Live From Spotify</span>
          </p>
        </>
      ) : (
        <>
          <img src={PauseIcon} height={30} width={30} alt="Pause Icon" />
          <p style={{ paddingLeft: "15" }}>
            Not Playing - <span style={{ color: "grey" }}>Spotify</span>
          </p>
        </>
      )}
    </Wrapper>
  );
};

export default Spotify;
